import { ColorsRelatedActions, UserMessageTypes } from "deblank-api-types"
import { ColorSelectValue } from "@/components/WidgetsByType/Colors/WidgetColorsTypes"
import {
	ConfigStepType, RelatedActionContext, RelatedActionData,
	SelectType, OneStepAction,
} from "../RelatedActionsTypes"
import { RelatedActionsDefaultValues } from "../RelatedActionsDefaults"
import { valueIsEmpty } from "../utils/RelatedUtils"

type Step1 = ColorSelectValue

type RelatedState = OneStepAction<Step1>

export const Color_Related_Action_Config_8
	= ((): RelatedActionData<RelatedState> => {

		const handleProcessAction = (context: RelatedActionContext<RelatedState>) =>
			async (data: RelatedState) => {
				await context.onAddPendingMessage({
					type: UserMessageTypes.relatedAction,
					informationBlocks: [{
						title: "Add a warm color",
					},],
					message: "Add a warm color",
					idRefs: data.step1.idRef ? [data.step1.idRef,] : undefined,
					// eslint-disable-next-line max-len
					prompt: `Based on the palette selected by the user ${data.step1.widgetItemDetails.palette}, add an extra warm color to the palette, if the palette already has 10 colors, replace the last color with the new warm color.`,
					generatedByWidget: context.idRef,
				})
			}

		const handleStep1 = {
			get: (context: RelatedActionContext<RelatedState>) => {
				return context.currentValue.step1
			},
			set: (context: RelatedActionContext<RelatedState>) =>
				(data: ColorSelectValue) => {
					context.onSetState(prev => {
						return {
							...prev,
							step1: data,
						}
					})
				},
			isEmpty: (context: RelatedActionContext<RelatedState>) => {
				return valueIsEmpty(context.currentValue.step1.widgetItemDetails)
			},
		}

		return {
			id: ColorsRelatedActions.AddWarmColor,
			label: "Add a warm color",
			config: {
				defaultState: {
					step1: RelatedActionsDefaultValues.colors,
				},
				processAction: handleProcessAction,
				steps: [
					{
						title: "Select a color palette",
						componentType: ConfigStepType.select,
						selectType: SelectType.colors,
						required: true,
						onSelect: handleStep1.set,
						onGetValue: handleStep1.get,
						isEmpty: handleStep1.isEmpty,
					},
				],
			},
		}

	})()
