import { ColorsRelatedActions, UserMessageTypes } from "deblank-api-types"
import { ColorSelectValue } from "@/components/WidgetsByType/Colors/WidgetColorsTypes"
import {
	ConfigStepType, RelatedActionContext, RelatedActionData,
	SelectType, TwoStepAction, InputSelectValue,
} from "../RelatedActionsTypes"
import { RelatedActionsDefaultValues } from "../RelatedActionsDefaults"
import { valueIsEmpty } from "../utils/RelatedUtils"

type Step1 = ColorSelectValue
type Step2 = InputSelectValue

type RelatedState = TwoStepAction<Step1, Step2>

export const Color_Related_Action_Config_11
	= ((): RelatedActionData<RelatedState> => {

		const handleProcessAction = (context: RelatedActionContext<RelatedState>) =>
			async (data: RelatedState) => {
				const description = data.step2.value || ""

				await context.onAddPendingMessage({
					type: UserMessageTypes.relatedAction,
					informationBlocks: [{
						title: "Generate images with a color palette",
						userInput: description,
					},],
					message: `Generate images with a color palette. ${description ? description : ""}`,
					idRefs: data.step1.idRef ? [data.step1.idRef,] : undefined,
					// eslint-disable-next-line max-len
					prompt: `Generate images with a color palette. Consider the business description and the following instructions: ${description}`,
					generatedByWidget: context.idRef,
				})
			}

		const handleStep1 = {
			get: (context: RelatedActionContext<RelatedState>) => {
				return context.currentValue.step1
			},
			set: (context: RelatedActionContext<RelatedState>) =>
				(data: ColorSelectValue) => {
					context.onSetState(prev => {
						return {
							...prev,
							step1: data,
						}
					})
				},
			isEmpty: (context: RelatedActionContext<RelatedState>) => {
				return valueIsEmpty(context.currentValue.step1.widgetItemDetails)
			},
		}

		const handleStep2 = {
			get: (context: RelatedActionContext<RelatedState>) => {
				return context.currentValue.step2
			},
			set: (context: RelatedActionContext<RelatedState>) =>
				(data: InputSelectValue) => {
					context.onSetState(prev => {
						return {
							...prev,
							step2: {
								value: data.value,
							},
						}
					})
				},
			isEmpty: (context: RelatedActionContext<RelatedState>) => {
				return !context.currentValue.step2.value
			},
		}

		return {
			id: ColorsRelatedActions.GenerateImagesWithColorPalette,
			label: "Generate images with a color palette",
			config: {
				defaultState: {
					step1: RelatedActionsDefaultValues.colors,
					step2: {
						value: "",
					},
				},
				processAction: handleProcessAction,
				steps: [
					{
						title: "Select a color palette",
						componentType: ConfigStepType.select,
						selectType: SelectType.colors,
						required: true,
						onSelect: handleStep1.set,
						onGetValue: handleStep1.get,
						isEmpty: handleStep1.isEmpty,
					},
					{
						title: "Describe the image",
						componentType: ConfigStepType.select,
						selectType: SelectType.input,
						placeholder: "A young woman smiling on the mountain at sunset",
						required: false,
						onSelect: handleStep2.set,
						onGetValue: handleStep2.get,
						isEmpty: handleStep2.isEmpty,
					},
				],
			},
		}

	})()
