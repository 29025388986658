import React, { useContext } from "react"
import { EntryQuestionResult, IdRefWidget, introQuestions, UserMessageTypes } from "deblank-api-types"
import BubbleAssistantQuestion from "@/components/Chat/BubbleAssistantQuestion/BubbleAssistantQuestion"
import { useRecoilValue } from "recoil"
import { selectorsMessages } from "@/recoil/ConversationsRecord/Selectors/Messages"
import { settersIntroQuestions } from "@/recoil/ConversationsRecord/Setters/CurrentIntroQuestions"
import { settersAssistantResponseTools } from "@/recoil/ConversationsRecord/Setters/AssistantResponseTools"
import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"
import { selectorsIntroQuestions } from "@/recoil/ConversationsRecord/Selectors/CurrentIntroQuestions"
import { SKIP_CONVERSATION_INTRO_NAME } from "@/recoil/ConversationsRecord/constants/introQuestions"


type Props = {
	idRef: IdRefWidget,
	widgetDetails: EntryQuestionResult,
}

const WidgetQuestion = (props: Props) => {
	const isLastMessage = useRecoilValue(selectorsMessages.isThisMessageLast(props.idRef.messageId))
	const someMessageIsLoading = useRecoilValue(selectorsMessages.someMessageIsLoading)
	const setIntroQuestions = settersIntroQuestions.useSetCurrentIntroQuestions()
	const addPendingMessage = settersAssistantResponseTools.useAddPendingMessageToConversation()
	const currentIntroQuestion = useRecoilValue(selectorsIntroQuestions.currentIntroQuestion)
	const { trackConversationEvent, } = useContext(TrackConversationEventContext)

	const onSkipIntroductionHandler = async () => {

		setIntroQuestions(null)
		addPendingMessage({
			type: UserMessageTypes.text,
			message: SKIP_CONVERSATION_INTRO_NAME,
			// eslint-disable-next-line max-len
			prompt: "The user skipped the initial questions. You must continue with the information collected or ask the user, 'Where would you like to begin?'",
		})
		await trackConversationEvent({
			eventName: "SkipIntroductionQuestionsEvent",
			question: introQuestions[currentIntroQuestion!].question,
		})
	}

	return (
		props.widgetDetails.data.results.map((widget) => (
			<BubbleAssistantQuestion key={widget.id}
				idRef={props.idRef}
				message={widget.message}
				example={widget.example}
				currentStep={widget.currentStep}
				totalSteps={widget.totalSteps}
				isLastMessage={isLastMessage}
				isLoading={someMessageIsLoading}
				onSkip={onSkipIntroductionHandler}
			/>
		))
	)
}

export default WidgetQuestion
