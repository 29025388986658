import React from "react"
import Styles from "./WidgetBubbleUserMessageFont.module.scss"
import { selectorsFontCache } from "@/recoil/FontCache/Selectors"
import { useRecoilValue } from "recoil"
import { CommonWidgetFontsItemProps } from "../WidgetFontsTypes"
import { FontDetailsByPairing, formatFontNamePairing, } from "../_helper/FontDetailsByPairing"

const WidgetBubbleUserMessageFont = (props: CommonWidgetFontsItemProps) => {
	const fontSlugsByPairing = FontDetailsByPairing({
		pairing: props.metadata.pairing,
		baseFontSlug: props.metadata.baseFont || null,
		fontSlug: props.widgetItemDetails.slug,
	})

	const fontMainDetails = useRecoilValue(selectorsFontCache.fontFromCache(fontSlugsByPairing.titleSlug))
	const fontPairingDetails = useRecoilValue(selectorsFontCache.fontFromCache(fontSlugsByPairing.paringSlug || undefined))

	const getFontNames = formatFontNamePairing({
		pairingFontName: fontPairingDetails?.metadata.name,
		mainFontName: fontMainDetails?.metadata.name || "",
		pairing: props.metadata.pairing,
	})

	return (
		<article className={Styles.selected_font}>
			<span className={Styles.fonts_name}>
				{getFontNames}
			</span>
			{fontMainDetails
				&& <h3 className={Styles.title}
					style={fontMainDetails.fontCSSProperties}>
					{props.metadata.title}
				</h3>
			}
			{fontPairingDetails
				&& <p className={Styles.paragraph}
					style={fontPairingDetails.fontCSSProperties}>
					{props.metadata.description}
				</p>
			}
		</article>
	)
}

export default WidgetBubbleUserMessageFont
