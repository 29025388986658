import React from "react"
import { UserMessage, UserMessageTypes } from "deblank-api-types"
import ChatBubbleUserRelatedAction from "./BubbleUserRelatedAction"
import ChatBubbleUserText from "./BubbleUserText"


const ChatBubbleUser = (props: { userMessage: UserMessage, }) => {
	if (props.userMessage.type && props.userMessage.type === UserMessageTypes.relatedAction) {
		return (
			<ChatBubbleUserRelatedAction
				idRefs={props.userMessage.idRefs}
				message={props.userMessage.message}
				informationBlocks={props.userMessage.informationBlocks}
			/>
		)
	} else {
		return (
			<ChatBubbleUserText
				idRefs={props.userMessage.idRefs}
				message={props.userMessage.message}
			/>
		)
	}
}

export default ChatBubbleUser
