import { RelatedActionsDefaultValues } from "../RelatedActionsDefaults"
import {
	ConfigStepType, RelatedActionContext, RelatedActionData,
	SelectType, ThreeStepAction, InputSelectValue,
} from "../RelatedActionsTypes"
import { ImageSelectValue } from "@/components/WidgetsByType/Images/WidgetImagesTypes"
import { ImagesRelatedActions, UserMessageTypes } from "deblank-api-types"
import { valueIsEmpty } from "../utils/RelatedUtils"

type Step1 = ImageSelectValue
type Step2 = InputSelectValue
type Step3 = InputSelectValue

type Image_Related_2_State = ThreeStepAction<Step1, Step2, Step3>

export const Image_Related_Action_Config_2
	= ((): RelatedActionData<Image_Related_2_State> => {

		const handleProcessAction = (context: RelatedActionContext<Image_Related_2_State>) =>
			async (data: Image_Related_2_State) => {
				const search = data.step2.value || ""
				const prompt = data.step3.value || ""

				await context.onAddPendingMessage({
					type: UserMessageTypes.relatedAction,
					informationBlocks: [{
						title: "Change image content",
						userInput: `Change image content. Remove: ${search}. Replace it with: ${prompt}.`,
					}, {
						title: "Remove:",
						userInput: search,
					},
					{
						title: "Replace with:",
						userInput: prompt,
					},],
					message: `Change image content. Remove: ${search}. Replace it with: ${prompt}.`,
					idRefs: data.step1.idRef ? [data.step1.idRef,] : undefined,
					prompt: `Change the content of the image. The search prompt is ${search} and the new prompt is ${prompt}`,
					generatedByWidget: context.idRef,
				})
			}

		const handleStep1 = {
			get: (context: RelatedActionContext<Image_Related_2_State>) => {
				return context.currentValue.step1
			},
			set: (context: RelatedActionContext<Image_Related_2_State>) =>
				(data: ImageSelectValue) => {
					context.onSetState(prev => {
						return {
							...prev,
							step1: data,
						}
					})
				},
			isEmpty: (context: RelatedActionContext<Image_Related_2_State>) => {
				return valueIsEmpty(context.currentValue.step1.widgetItemDetails)
			},
		}

		const handleStep2 = {
			get: (context: RelatedActionContext<Image_Related_2_State>) => {
				return context.currentValue.step2
			},
			set: (context: RelatedActionContext<Image_Related_2_State>) =>
				(data: InputSelectValue) => {
					context.onSetState(prev => {
						return {
							...prev,
							step2: {
								value: data.value,
							},
						}
					})
				},
			isEmpty: (context: RelatedActionContext<Image_Related_2_State>) => {
				return !context.currentValue.step2.value
			},
		}

		const handleStep3 = {
			get: (context: RelatedActionContext<Image_Related_2_State>) => {
				return context.currentValue.step3
			},
			set: (context: RelatedActionContext<Image_Related_2_State>) =>
				(data: InputSelectValue) => {
					context.onSetState(prev => {
						return {
							...prev,
							step3: {
								value: data.value,
							},
						}
					})
				},
			isEmpty: (context: RelatedActionContext<Image_Related_2_State>) => {
				return !context.currentValue.step3.value
			},
		}

		return {
			id: ImagesRelatedActions.ChangeImageContent,
			label: "Change image content",
			config: {
				defaultState: {
					step1: RelatedActionsDefaultValues.images,
					step2: {
						value: "",
					},
					step3: {
						value: "",
					},
				},
				isBeta: true,
				processAction: handleProcessAction,
				steps: [
					{
						title: "Select an image",
						componentType: ConfigStepType.select,
						selectType: SelectType.images,
						required: true,
						onSelect: handleStep1.set,
						onGetValue: handleStep1.get,
						isEmpty: handleStep1.isEmpty,
					},
					{
						title: "Which element would you want to remove?",
						componentType: ConfigStepType.select,
						selectType: SelectType.input,
						placeholder: "The bike",
						required: true,
						onSelect: handleStep2.set,
						onGetValue: handleStep2.get,
						isEmpty: handleStep2.isEmpty,
					},
					{
						title: "What do you want in its place?",
						componentType: ConfigStepType.select,
						selectType: SelectType.input,
						placeholder: "A vintage car with luggage on top",
						required: true,
						onSelect: handleStep3.set,
						onGetValue: handleStep3.get,
						isEmpty: handleStep3.isEmpty,
					},
				],
			},
		}

	})()
