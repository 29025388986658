import { ColorsRelatedActions, DisplayWidgetType, EntryColorsResultWithoutId, UserMessageTypes } from "deblank-api-types"
import { ColorSelectValue } from "@/components/WidgetsByType/Colors/WidgetColorsTypes"
import {
	ConfigStepType, RelatedActionContext, RelatedActionData,
	SelectType, OneStepAction,
} from "../RelatedActionsTypes"
import { ModifyColorPalettesUtils } from "./utils/ModifyColorPalettesUtils"
import { RelatedActionsDefaultValues } from "../RelatedActionsDefaults"
import { getRandomItemFromArray } from "@/utils/arrayUtils"
import { valueIsEmpty } from "../utils/RelatedUtils"

type Step1 = ColorSelectValue

type RelatedState = OneStepAction<Step1>

export const Color_Related_Action_Config_2
	= ((): RelatedActionData<RelatedState> => {

		const handleProcessAction = (context: RelatedActionContext<RelatedState>) =>
			async (data: RelatedState) => {
				const newPalettes = ModifyColorPalettesUtils.generateSaturationVariations(data.step1.widgetItemDetails.palette)
				const palettes = newPalettes.map((palette) => ({
					palette,
				}))
				const output: EntryColorsResultWithoutId = {
					type: DisplayWidgetType.Colors,
					metadata: null,
					data: {
						isPaginated: true,
						pages: [{
							results: palettes,
							pageNumber: 0,
						},],
					},
				}

				await context.onAddCreateNotifications({
					notifications: [
						{
							relatedActions: [
								ColorsRelatedActions.HowToApplyColorsInUIDesign,
								ColorsRelatedActions.CheckColorPaletteAccessibility,
								ColorsRelatedActions.GenerateMockupWithColorPalette,
								getRandomItemFromArray([
									ColorsRelatedActions.GenerateImagesWithColorPalette,
									ColorsRelatedActions.SearchForImagesWithColorPalette,
								]),
							],
							output,
							internalMessageForAgent: `The user asked for 3 palettes with different
							saturations levels based on this palette: ${data.step1.widgetItemDetails.palette}.`,
							userMessage: {
								type: UserMessageTypes.relatedAction,
								informationBlocks: [{
									title: "Expand levels of saturation",
								},],
								message: "Expand levels of saturation",
								idRefs: [data.step1.idRef,],
							},
							generatedByWidget: context.idRef,
						},
					],
				})
			}

		const handleStep1 = {
			get: (context: RelatedActionContext<RelatedState>) => {
				return context.currentValue.step1
			},
			set: (context: RelatedActionContext<RelatedState>) =>
				(data: ColorSelectValue) => {
					context.onSetState(prev => {
						return {
							...prev,
							step1: data,
						}
					})
				},
			isEmpty: (context: RelatedActionContext<RelatedState>) => {
				return valueIsEmpty(context.currentValue.step1.widgetItemDetails)
			},
		}

		return {
			id: ColorsRelatedActions.ExpandSaturationLevels,
			label: "Expand levels of saturation",
			config: {
				defaultState: {
					step1: RelatedActionsDefaultValues.colors,
				},
				processAction: handleProcessAction,
				steps: [
					{
						title: "Select a color palette",
						componentType: ConfigStepType.select,
						selectType: SelectType.colors,
						required: true,
						onSelect: handleStep1.set,
						onGetValue: handleStep1.get,
						isEmpty: handleStep1.isEmpty,
					},
				],
			},
		}

	})()
