import React from "react"
import Styles from "./BubbleUser.module.scss"
import * as Sentry from "@sentry/react"
import BubbleUserWidgetSelector from "./BubbleUserWidgetSelector/BubbleUserWidgetSelector"
import { IdRefType, IdRefWidgetWithPagination, IdRefWidgetWithPaginationItem, MessageInformationBlock, } from "deblank-api-types"


const ChatBubbleUserRelatedAction = (props: {
	message: string,
	idRefs: (IdRefWidgetWithPaginationItem | IdRefWidgetWithPagination)[] | null,
	informationBlocks: MessageInformationBlock[],
}) => {
	return (
		<div className={Styles.bubble_container}>

			<div className={Styles.bubble}>
				{props.idRefs && props.idRefs.length > 0
					&& <>
						<div className={Styles.selected_outputs_container}>
							{props.idRefs.map((widgetRef, i) => {
								if (widgetRef.type === IdRefType.WidgetWithPaginationItem) {
									return <BubbleUserWidgetSelector
										key={`${widgetRef.messageId}-${i}`}
										idRef={widgetRef}
									/>
								}

								const errorMessage = `IdRef ${widgetRef.type} is not supported in Bubble User`
								Sentry.captureMessage(errorMessage)
								throw new Error(errorMessage)
							})}
						</div>
						<div className={Styles.divider} />
					</>
				}{
					props.informationBlocks && props.informationBlocks.map((block, i) => (
						<div key={i}>
							{block.title && <div className={Styles.title}>
								{block.title}
							</div>}
							{block.userInput && <div>
								{block.userInput}
							</div>}
						</div>
					))
				}
			</div>

		</div>
	)
}

export default ChatBubbleUserRelatedAction
