import { FontsRelatedActions, UserMessageTypes } from "deblank-api-types"
import { FontSelectValue } from "@/components/WidgetsByType/Fonts/WidgetFontsTypes"
import {
	ConfigStepType, RelatedActionContext, RelatedActionData,
	SelectType, TwoStepAction, InputSelectValue,
} from "../RelatedActionsTypes"
import { RelatedActionsDefaultValues } from "../RelatedActionsDefaults"
import { valueIsEmpty } from "../utils/RelatedUtils"

type Step1 = FontSelectValue
type Step2 = InputSelectValue

type RelatedState = TwoStepAction<Step1, Step2>

export const Font_Related_Action_Config_3
	= ((): RelatedActionData<RelatedState> => {

		const handleProcessAction = (context: RelatedActionContext<RelatedState>) =>
			async (data: RelatedState) => {
				const description = data.step2.value || ""

				await context.onAddPendingMessage({
					type: UserMessageTypes.relatedAction,
					informationBlocks: [{
						title: "Generate mockups with a font",
						userInput: description,
					},],
					message: `Generate mockups with the selected font. ${description ? description : ""}`,
					idRefs: data.step1.idRef ? [data.step1.idRef,] : undefined,
					prompt: `Generate mockups. ${description}`,
					generatedByWidget: context.idRef,
				})
			}

		const handleStep1 = {
			get: (context: RelatedActionContext<RelatedState>) => {
				return context.currentValue.step1
			},
			set: (context: RelatedActionContext<RelatedState>) =>
				(data: FontSelectValue) => {
					context.onSetState(prev => {
						return {
							...prev,
							step1: data,
						}
					})
				},
			isEmpty: (context: RelatedActionContext<RelatedState>) => {
				return valueIsEmpty(context.currentValue.step1.widgetItemDetails)
			},
		}

		const handleStep2 = {
			get: (context: RelatedActionContext<RelatedState>) => {
				return context.currentValue.step2
			},
			set: (context: RelatedActionContext<RelatedState>) =>
				(data: InputSelectValue) => {
					context.onSetState(prev => {
						return {
							...prev,
							step2: {
								value: data.value,
							},
						}
					})
				},
			isEmpty: (context: RelatedActionContext<RelatedState>) => {
				return !context.currentValue.step2.value
			},
		}

		return {
			id: FontsRelatedActions.GenerateMockupsWithFont,
			label: "Generate mockups with a font",
			config: {
				defaultState: {
					step1: RelatedActionsDefaultValues.fonts,
					step2: {
						value: "",
					},
				},
				isBeta: true,
				processAction: handleProcessAction,
				steps: [
					{
						title: "Select a font",
						componentType: ConfigStepType.select,
						selectType: SelectType.fonts,
						required: true,
						onSelect: handleStep1.set,
						onGetValue: handleStep1.get,
						isEmpty: handleStep1.isEmpty,
					},
					{
						title: "Describe how the mockup should look like",
						componentType: ConfigStepType.select,
						selectType: SelectType.input,
						placeholder: "A box that reads Wander Coffee Co. with elegant lettering and a vibrant background",
						required: true,
						onSelect: handleStep2.set,
						onGetValue: handleStep2.get,
						isEmpty: handleStep2.isEmpty,
					},
				],
			},
		}

	})()
