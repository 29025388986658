import React from "react"
import Styles from "./FontSelectCard.module.scss"
import { FontResponse, FontsMetadata, } from "deblank-api-types"
import { useRecoilValue } from "recoil"
import { selectorsFontCache } from "@/recoil/FontCache/Selectors"
import classNames from "classnames"

type Props = {
	fontData: FontResponse,
	metadata: FontsMetadata,
}

const FontSelectCard = (props: Props) => {
	const secondaryFontSlug = props.metadata.baseFont
	const fontFromCache = useRecoilValue(selectorsFontCache.fontFromCache(props.fontData.slug))
	const secondaryFontSlugFromCache = useRecoilValue(selectorsFontCache.fontFromCache(secondaryFontSlug || ""))
	const fontNames = secondaryFontSlug
		? `${secondaryFontSlugFromCache?.metadata.name} - ${fontFromCache?.metadata.name}`
		: fontFromCache?.metadata.name

	const baseClasses = classNames(
		Styles.base_font,
		Styles.ellipsis,
	)

	const titleClasses = classNames(
		Styles.title,
		Styles.ellipsis,
	)

	const shortDescription = props.metadata.description.split(" ").slice(0, 2)
		.join(" ")

	return (
		fontFromCache
			? <>
				<div className={baseClasses}>
					{fontNames}
				</div>
				<div style={
					secondaryFontSlug
						? secondaryFontSlugFromCache?.fontCSSProperties
						: fontFromCache.fontCSSProperties} className={titleClasses} >
					{props.metadata.title}
				</div>
				{secondaryFontSlug && <div
					style={fontFromCache?.fontCSSProperties}
					className={Styles.ellipsis} >
					{shortDescription}
				</div>}
			</>
			: <></>
	)
}

export default FontSelectCard
