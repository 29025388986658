import { FontsRelatedActions, UserMessageTypes } from "deblank-api-types"
import { FontSelectValue } from "@/components/WidgetsByType/Fonts/WidgetFontsTypes"
import {
	ConfigStepType, RelatedActionContext, RelatedActionData,
	SelectType, OneStepAction,
} from "../RelatedActionsTypes"
import { RelatedActionsDefaultValues } from "../RelatedActionsDefaults"
import { valueIsEmpty } from "../utils/RelatedUtils"

type Step1 = FontSelectValue

type Font_Related_2_State = OneStepAction<Step1>

export const Font_Related_Action_Config_2
	= ((): RelatedActionData<Font_Related_2_State> => {

		const handleProcessAction = (context: RelatedActionContext<Font_Related_2_State>) =>
			async (data: Font_Related_2_State) => {
				await context.onAddPendingMessage({
					type: UserMessageTypes.relatedAction,
					informationBlocks: [{
						title: "Get font pairing",
					},],
					message: "Get font pairing",
					// eslint-disable-next-line max-len
					prompt: "Choose a complementary font that harmonizes with the primary font to achieve a balanced and cohesive typographic design",
					idRefs: data.step1.idRef ? [data.step1.idRef,] : undefined,
					generatedByWidget: context.idRef,
				})
			}

		const handleStep1 = {
			get: (context: RelatedActionContext<Font_Related_2_State>) => {
				return context.currentValue.step1
			},
			set: (context: RelatedActionContext<Font_Related_2_State>) =>
				(data: FontSelectValue) => {
					context.onSetState(prev => {
						return {
							...prev,
							step1: data,
						}
					})
				},
			isEmpty: (context: RelatedActionContext<Font_Related_2_State>) => {
				return valueIsEmpty(context.currentValue.step1.widgetItemDetails)
			},
		}

		return {
			id: FontsRelatedActions.GetFontPairing,
			label: "Get font pairing",
			config: {
				defaultState: {
					step1: RelatedActionsDefaultValues.fonts,
				},
				processAction: handleProcessAction,
				steps: [
					{
						title: "Select a font",
						componentType: ConfigStepType.select,
						selectType: SelectType.fonts,
						required: true,
						onSelect: handleStep1.set,
						onGetValue: handleStep1.get,
						isEmpty: handleStep1.isEmpty,
					},
				],
			},
		}

	})()
