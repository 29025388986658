/* eslint-disable react-hooks/rules-of-hooks */
import { produce } from "immer"
import { useRecoilCallback } from "recoil"
import { atomFontsCache } from "./Atom"
import { FontItem } from "deblank-api-types"


const useAddFontsToCache = () => {
	return useRecoilCallback(({ set, }) => (
		newFonts: FontItem[],
	) => {
		set(atomFontsCache, previousFonts => produce(previousFonts, draft => {

			const uniqueNewFonts = Array.from(
				new Set(newFonts.filter(Boolean).map(font => font.slug))
			).map(slug => newFonts.find(font => font.slug === slug)) as FontItem[]
			uniqueNewFonts.forEach(font => {
				if (!draft.fonts[font.slug]) {
					draft.fonts[font.slug] = {
						...font,
						isCSSLoaded: false,
					}
				}
			})

			draft.pendingSlugs = []
		}))
	})
}

const useAddSlugsToPendingSlugs = () => {
	return useRecoilCallback(({ set, }) => (
		slugs: string[],
	) => {
		set(atomFontsCache, previousFonts => produce(previousFonts, draft => {
			draft.pendingSlugs = slugs
		}))
	})

}

const useUpdateFontLoadState = () => {
	return useRecoilCallback(({ set, }) => (
		slug: string,
	) => {
		set(atomFontsCache, previousFonts => produce(previousFonts, draft => {
			const font = draft.fonts[slug]

			if (font) {
				font.isCSSLoaded = true
			}
		}))
	})
}


export const settersFontCache = {
	useAddFontsToCache,
	useUpdateFontLoadState,
	useAddSlugsToPendingSlugs,
}
